<template>
  <div class="container">
    <div 
      v-for="(episode, index) in episodesToDisplay" 
      :key="episode.id || index"
      class="img-wrap"
      :style="getDelayStyle(index)"
    >
      <!-- 이미지 렌더링 -->
      <img 
        v-if="episode.type === 'image'"
        ref="images"
        class="responsive-media"
        :src="placeholderImage"
        :data-src="episode.thumbnail"
        :alt="episode.description"
        @error="setDefaultImage"
      />
      
      <!-- 비디오 렌더링 -->
      <div v-else-if="episode.type === 'video'" class="video-wrapper">
        <iframe 
          v-if="episode.videoLink"
          class="responsive-media"
          :src="convertYoutubeLink(episode.videoLink)"
          frameborder="0"
          allowfullscreen
        ></iframe>
        <span class="video-link" v-if="episode.videoLink">
          <a :href="episode.videoLink" target="_blank" rel="noopener noreferrer">
            Watch Video
          </a>
        </span>
      </div>
      
      <!-- 설명 텍스트 -->
      <span v-if="episode.description" class="description">{{ episode.description }}</span>
    </div>
  </div>
</template>

<script>
import placeholderImage from '@/assets/placeholder-image.png';

export default {
  name: 'RecentCompView',
  props: {
    episodes: {
      type: Array,
      required: true
    },
    shuffle: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      placeholderImage,
      episodesToDisplay: []
    };
  },
  created() {
    this.prepareEpisodes();
  },
  mounted() {
    this.$nextTick(() => {
      this.initIntersectionObserver();
    });
  },
  methods: {
    prepareEpisodes() {
      // 쉐도우 카피 후 정렬
      const copied = [...this.episodes];
      this.episodesToDisplay = this.shuffle
        ? copied.sort(() => Math.random() - 0.5)
        : copied;
    },
    setDefaultImage(event) {
      event.target.src = this.placeholderImage;
      event.target.classList.add('error-image');
    },
    convertYoutubeLink(link) {
      if (!link || typeof link !== 'string') return '';
      return link.includes('watch?v=')
        ? link.replace('watch?v=', 'embed/')
        : link;
    },
    getDelayStyle(index) {
      return { animationDelay: `${index * 100}ms` };
    },
    initIntersectionObserver() {
      const observer = new IntersectionObserver(this.handleIntersection, {
        root: null,
        rootMargin: '0px',
        threshold: 0.1
      });

      // $refs.images는 ref="images" 요소들의 배열
      const images = this.$refs.images || [];
      images.forEach(img => {
        if (img && img.tagName === 'IMG') {
          observer.observe(img);
        }
      });
    },
    handleIntersection(entries, observer) {
      entries.forEach(entry => {
        if (entry.isIntersecting && entry.target.tagName === 'IMG') {
          this.loadHighResImage(entry.target, observer);
        }
      });
    },
    loadHighResImage(img, observer) {
      const highResSrc = img.getAttribute('data-src');
      if (!highResSrc) return;

      const tempImg = new Image();
      tempImg.src = highResSrc;
      tempImg.onload = () => {
        img.src = highResSrc;
        img.classList.add('loaded');
        // 자연 비율로 부모 컨테이너의 aspect-ratio 설정
        img.parentElement.style.aspectRatio = `${tempImg.naturalWidth} / ${tempImg.naturalHeight}`;
        observer.unobserve(img);
      };
      tempImg.onerror = () => {
        this.setDefaultImage({ target: img });
        observer.unobserve(img);
      };
    }
  }
};
</script>

<style scoped>
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  gap: 5px;
}

.img-wrap {
  position: relative;
  overflow: hidden;
  opacity: 0;
  animation: fadeIn 0.5s forwards;
  width: 100%;
  aspect-ratio: 4 / 3;
}

.responsive-media {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  transition: opacity 0.5s ease;
}

.responsive-media.loaded {
  opacity: 1;
}

.description {
  position: absolute;
  bottom: 5%;
  left: 0;
  right: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  padding: 5px;
  border-radius: 2px;
  font-size: 11px;
  max-width: 80%;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.video-link {
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .container {
    grid-template-columns: repeat(1, 1fr);
  }
  .description {
    max-width: calc(100% - 40px);
  }
}

@media screen and (max-width: 480px) {
  .container {
    grid-template-columns: 1fr;
  }
}
</style>