import { createApp } from 'vue';
import App from './App';
import router from './router';
import VueGtag from 'vue-gtag-next';
import VueLazyLoad from 'vue-lazyload-next';
import { createHead } from '@vueuse/head';
import './global.css';

const app = createApp(App);

const head = createHead();

app.use(router);

app.use(VueGtag, {
  property: {
    id: process.env.VUE_APP_GA_MEASUREMENT_ID
  },
  isEnabled: process.env.NODE_ENV === 'production',
  config: { debug: true } // 디버그 모드 활성화
}, router);

app.use(VueLazyLoad, {
  preLoad: 1.2,
  attempt: 1
});
app.use(head);

app.mount('#app');





