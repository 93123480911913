<template>
  <div class="content-detail-page">
    <div v-if="contentItem.thumbnails && contentItem.thumbnails.length > 0" class="content-image">
      <img
        v-for="(thumbnail, index) in contentItem.thumbnails"
        :key="index"
        :src="thumbnail"
        :alt="contentItem.description || 'No description available'"
      />
    </div>
    <div class="content-text">
      <h4 v-if="contentItem.title">{{ contentItem.title }}</h4>
      <div v-html="contentItem.description"></div>
      <span v-if="contentItem.year">{{ contentItem.year }}</span>
    </div>
    <div class="go-back-container">
      <h5 class="go-back-button" @click="goBack">돌아가기</h5>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import imagesData from '@/data/images.js';

export default {
  setup() {
    const route = useRoute();
    const router = useRouter();

    const workId = route.params.workId;
    const contentId = parseInt(route.params.contentId, 10);

    // workData와 contentItem 접근 시 안전성 강화
    const workData = computed(() => {
      const data = imagesData[workId] || { images: [], content: [], combinedContent: [] };
      return data;
    });

    const contentItem = computed(() => {
      const contents = workData.value?.combinedContent || [];
      return contents[contentId] || {};
    });

    const goBack = () => {
      router.back();
    };

    return {
      workData,
      contentItem,
      goBack
    };
  }
};
</script>

<style scoped>
.content-detail-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: left;
}

.content-image {
  display: grid;
  gap: 10px;
  max-width: 500px;
  margin-bottom: 20px;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
}

/* 여기서부터는 이미지 관련 원본 스타일 유지 (사진 크기 관련 수정 없음) */
.content-image img {
  object-fit: cover;
  width: 100%;
  height: auto;
}

.content-image img:nth-child(1):only-child {
  width: 500px;
}

.content-image img:nth-child(-n+2):only-of-type,
.content-image img:nth-child(3):nth-last-child(1) {
  width: 240px;
}

.content-image img:nth-child(3):last-child,
.content-image img:nth-child(5):last-child {
  width: 500px;
  grid-column: 1 / -1;
}

/* 반응형 */
@media (max-width: 500px) {
  .content-image {
    grid-template-columns: 1fr;
  }

  .content-image img {
    width: 100%;
    max-width: 100%;
    height: auto;
  }

  .content-text {
    width: 100%;
    max-width: 100%;
  }
}

.content-text {
  max-width: 500px;
  margin-bottom: 20px;
}

/* 돌아가기 버튼 스타일 */
.go-back-container {
  text-align: center;
  margin-top: 10px;
}

.go-back-button {
  font-weight: 400;
  cursor: pointer;
  border-bottom: 1px solid #cfff00;
  display: inline-block;
  padding: 5px;
}

.go-back-button:hover {
  opacity: 0.8;
}
</style>