// data/CV.js

export const cvData = {
    bio: `1987년 서울 출생. 현재 서울과 광주를 기반으로 활동하며, 사진을 주매체로 작업을 이어오고 있다. 작업을 통해 신체와 사물의 지각 방식을 재구성하는 도구로, 물질성과 비물질성 사이의 간극을 탐구하며 물리적 현실과 디지털 세계를 연결하는 새로운 감각적 경험을 제안한다. 광학적 기술의 발전이 지각의 방식을 변화시키고 있다는 점에 주목한다. 과거에는 신체를 통해 사물을 직접적으로 지각하는 방식이 일반적이었다면, 오늘날 사진과 디지털 스크린 같은 매체들이 지각의 과정을 중개하며, 경험의 층위를 확장하고 사물과 신체 간의 관계를 새롭게 정의한다.
사진은 단순히 현실을 재현하는 도구를 넘어, 사물과 신체 감각을 혼합하고 변화시키는 변환 장치로 기능한다. 이를 통해 사진은 사물과 신체 간의 물리적 경계를 흐리며, 디지털 환경 속에서 신체의 유약함, 부드러움, 중력의 부재를 드러내고, 새로운 물리적 개념에 대한 상상을 자극한다. 작업은 디지털 매체의 매끄러운 표현력과 실재하는 세계의 물질성 사이에서 발생하는 간극을 탐구한다. 신체 감각과 데이터, 전통적 사물과 현대적 재현 방식의 교차를 통해 사진 매체의 가능성과 한계를 실험적으로 확장하며, 사진과 사물, 디지털 스크린, 그리고 다양한 인쇄 매체를 활용한 시각적 변환과 재해석을 시도한다.
사진매체를 통해 현실과 환영, 물질과 비물질, 신체와 디지털 세계 사이의 경계를 넘나들며, 물리적 현실과 디지털 세계를 연결하는 새로운 감각적 경험을 제안하는 작업으로 만들고자 한다.`,
  
    edu: `2020 중앙대학교 일반대학원 조형예술학과 순수사진 전공, 사진학석사 졸업
  2014 중앙대학교 공연영상창작 학부 사진전공, 미술학사 졸업
  2013 Fachhochschule Bielefeld Fotografie & Medium, Exchange student, 빌레펠트, 독일, 수료`,
  
    solo: 
 `2024 워터포토아우토맛 Water Photoautomat, 스페이스 디디에프, 광주, 한국
  2021 미들턴 Middle turn, 공간 X Shift, 서울, 한국
  2018 우리는 미래에도 돌위에 살 것인가, 일현미술관 을지스페이스, 서울, 한국
  2018 말할 수 없는 것에 대해서는 침묵하라, Encounter 2018- Daily Rations, 공공미술 프로젝트, 뭄바이, 인도
  2016 회한의 무게, 대안공간 눈, 수원, 한국
  2016 수원공지, Cabinet Seoul Sewunsanga, 서울, 한국`,
  
    group: `2023 사진, 익숙하고 낯선 말들, 아미미술관, 당진, 한국
  2023 Spaceless, 제14회 광주비엔날레, 스위스파빌리온, 광주, 한국
  2022 Spaceless, 주한 스위스 대사관, 서울 한국
  2022 지금 우리는 어디에?, 성곡미술관, 한국, 서울
  2022 사진의 경계, 광주시립 사진전시관, 광주, 한국
  2022 길위에서, 5.18기념문화재단, 광주, 한국
  2021 끝 다음에 도착한, 산수싸리, 광주, 한국
  2021 소환술 Calling, d/p, 서울, 한국
  2020 의문문, 캔파운데이션, 서울, 한국
  2019 간극의 시간, 일현 미술관, 양양, 한국
  2018 Seoul, Mumbai to New Delhi, 주인도 한국문화원, 뉴델리, 인도
  2017 환상계단, 커뮤니티 아트 2017, 안녕하세요, 서울시립 북서울미술관, 서울, 한국
  2016 꽃 불, 우주여자_지리산 실상사 예술프로젝트 2016, 지리산 실상사, 남원, 한국
  2016 Slices On Time, 빌라 로마나 하우스, 피렌체, 이탈리아
  2016 New Generation – SIZAK, 라즈니아 현대 미술관 센터, 그단스크, 폴란드
  2016 Different Dimension, 사진페스티벌, 노보시비르스크 미술관, 노보로시비르스크, 러시아
  2016 Open-end(ed), 서학동 사진관, 전주, 한국
  2016 기억의 재해석, 프로이트 미술관, 상테 페테르 부르크, 러시아
  2015 일현 그랜트 트래블 2015, 일현 미술관, 양양, 한국
  2015 포토아일랜드 페스티벌 2015 지금, 더 사우스 스튜디오스, 더블린, 아일랜드
  2015 장님, 코끼리 만지듯이, 지금여기, 서울, 한국
  2013 더 텍사스 프로젝트, 더 텍사스 프로젝트, 서울,한국
  2013 UAMO play time, Einstein Kultur, 뮌헨, 독일`,
  
    project: `2020 아시아문화연구단, 2020 아시아문화포럼 - 지역연구(국제교류 분야), ACC, 광주, 한국
  2020 산수싸리, 큐레토-리얼리즘 ver.2, 전시 기획 워크샵, 광주문화재단, 광주, 한국
  2020 캔 창작자 워크숍 참여 C2A, Can Foundation, 서울
  2020 The-Scrap, Happy Together, 참여, 동대문구 왕산로 9길 24, 한국
  2019 한옥이라는 집, 공공미술 프로젝트, 전시, 협력 내셔널트러스트-리마크프레스, 서울, 한국
  2017 우리는 미래에도 돌위에 살것인가, 전시, Cabinet Seoul Sewunsanga, 서울, 한국
  2016~2018 캐비넷 서울_세운상가, 아티스트 러닝 스페이스 기획 및 운영, 서울, 한국
  2016 The-Scrap 작가 참여, 동대문구 왕산로 9길 24, 한국
  2016 UE8 서울 아트북페어, 작가 참여, 일민미술관, 한국`,
  
    selectedCollection: `2025 H-Artlab 3기, 광주광역시, 레지던시 
  2025 다원예술 창작산실(공간), 스페이스 디디에프, 한국문화예술위원회
  2024 창작 지원금, 광주문화재단 문화예술지원사업 창작지원금
  2022 제작 지원금, 주한스위스대사관, 한국-스위스 교류 60주년, <Spaceless> 프로젝트
  2020 작품소장, 신진미술인 지원을 통한 일상전시, 서울특별시청 문화본부 박물관과
  2018 트래블 그랜트 선정, 일현 트래블 그랜트 10주년 공모전, 일현 미술관, 한국
  2016 레지던시 선정, 빌라 로마나 <Slice on Time> 프로젝트&워크샵, 빌라 로마나, Deutsche Bank Foundation, 독일 문화 및 미디어 연방부
  2015 트래블 그랜트 선정, 일현 그랜트 트래블 2015, 일현 미술관, 한국`,
  
    article: 
  `사진예술, 전시리뷰, 5월호, 2023
  사진예술, 인터뷰, Apr, 2022
  보스토크VOSTOK, 인터뷰, May, 2021
  사진예술, 인터뷰, Apr, 2021
  포토닷, 인터뷰, Link Path, May, 2016
  경향신문, 기사, May, 2016
  이미지 조각모음, 인터뷰이, Nov, 2016
  아트 인 컬쳐, 인터뷰, New Look, Aug, 2015`,
  
    career: 
  `2022~2023, 에비예술인 지원사업, DDF 운영 및 영상 촬영, 홈페이지 유지보수, ARKO
  2023 Esquire, Watch special section 촬영
  2020 GQ Magazine, Item feature section 촬영, 26호
  2019 W Korea Magazine, 피쳐섹션 촬영
  2019 Artnow Magazine 26호, 아이템 섹션 광고 지면 촬영
  2018~2020(주)일에프육공디 Cheif of Design officer, UI/UX 웹 디자인 총괄 및 회사운영
  2018-2020 (주)H&P 종합광고대행사, 캐리어 에어컨, 도시바 등 전자제품 광고 사진 촬영
  2018 포토샵을 활용한 사진보정교실, 서울시 50+ 재단, 포토샵 활용 및 디지털 사진 리터칭 강의
  2018 사진입문: 초보자를 위한 A to Z, 서울시 50+ 재단, 사진 강의 및 워크샵 진행
  2016 LG 디지털 무선 사업부, Pilot Project - 사진 공유 서비스 프로젝트 참여
  2015 Inkjet Printing Workshop, 중앙대학교 사진학과 잉크젯 프린팅 워크샵 진행
  2015~2016 Teaching Assistant 중앙대학교 사진학과 조교, 국제교류 및 강의 진행 보조, 기자재관리 담당`,
  
    contact: 
    `SPACE.DDF 운영자,
  <a style="border-bottom : 1px solid #cfff00" target='_blank' href="https://www.instagram.com/p/CRWGZZCAeWa/?utm_medium=copy_link">@space.ddf</a><br/>
  윤태준 Taejun Yun / 010-4225-9442 / <a style="border-bottom : 1px solid #cfff00" href="mailto:taejun.foto@gmail.com">taejun.foto@gmail.com</a>
 <br/> 
  <p>
  개발 및 운영 <a style="border-bottom : 1px solid #cfff00" target="_blank" href="https://www.sizak.org">www.sizak.org</a> / <a style="border-bottom : 1px solid #cfff00" target="_blank" href="https://peer-up.com/">www.peer-up.com</a>
  
  <br/>
  인스타그램 <a style="border-bottom : 1px solid #cfff00" target="_blank" href="http://www.instagram.com/taejun.y_c">@taejun.y_c</a><br/>
  </p>
  `
  };
  