<template>
  <div class="container">
    <div class="txtframe">
      <div class="txtwrap">
        <h4>Biography</h4>
        <p class="preserve-space">{{ bio }}</p>
      </div>
      <div class="txtwrap">
        <h4>Education</h4>
        <p class="preserve-space">{{ edu }}</p>
      </div>
      <div class="txtwrap">
        <h4>Solo Show</h4>
        <p class="preserve-space">{{ solo }}</p>
      </div>
      <div class="txtwrap">
        <h4>Group Show (Selected)</h4>
        <p class="preserve-space">{{ group }}</p>
      </div>
      <div class="txtwrap">
        <h4>Project</h4>
        <p class="preserve-space">{{ project }}</p>
      </div>
      <div class="txtwrap">
        <h4>Selected&Collection</h4>
        <p class="preserve-space">{{ selectedCollection }}</p>
      </div>
      <div class="txtwrap">
        <h4>Article</h4>
        <p class="preserve-space">{{ article }}</p>
      </div>
      <div class="txtwrap">
        <h4>Career</h4>
        <p class="preserve-space">{{ career }}</p>
      </div>
      <div class="txtwrap">
        <h4 >Contact</h4>
        <p v-html="contact"></p>
      </div>
    </div>
  </div>
</template>

<script>
import { cvData } from '@/data/CV.js';

export default {
  name: 'CvCompView',
  data () {
    return cvData;
  }
}
</script>

<style scoped>
  .container {
    width: 100%;
  }

  .txtframe {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 70%;
  }

  .txtwrap {
    flex: 1 1 auto;
    margin: 0 0 0.5em 0;
  }

  .preserve-space {
    white-space: pre-line;
    font-weight: 200;
    text-align: justify;
    line-height: 1.5;
  }

  @media (max-width: 768px) {
    .txtframe {
      padding: 0;
      width: 100%;
    }
  }
</style>
