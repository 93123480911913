<template>
  <div class="network-gallery-page">
    <div class="network-image-section">
      <div class="network-work-title">
        <h5>{{ networkData.title }}</h5>
      </div>
      <div class="network-gallery-container">
        <div class="network-gallery-wrapper">
          <div
            class="network-img-wrap"
            v-for="(item, index) in networkData.combinedContent"
            :key="item.id || item.thumbnail || item.videoLink || index"
            :style="getItemStyles(item)"
            @click="handleItemClick(item, index)"
          >
            <img
              v-if="item.type === 'image'"
              class="network-responsive-image fade-in"
              :src="item.thumbnail"
              :alt="item.description"
              @error="setDefaultImage"
            />
            <iframe
              v-else-if="item.type === 'video'"
              class="network-video fade-in"
              :src="convertYoutubeLink(item.videoLink)"
              frameborder="0"
              allowfullscreen
            ></iframe>
            <div v-if="item.title" class="network-image-title">
              <h4>{{ item.title }}</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="border-top: 2px dashed #cfff00;"></div> <br/>
    <div class="network-text-container">
      <div class="network-text-section">
        <h5>작가노트 Artist's statement</h5>
        <div v-html="networkData.statement"></div>
      </div>
    </div>
  </div>
</template>
<script>
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { imagesData } from '@/data/images.js';

export default {
  name: 'NetworkGalleryPage',
  setup() {
    const router = useRouter();

    const networkData = computed(() =>
      imagesData.Network || { combinedContent: [], title: '제목 없음', statement: '설명 없음.' }
    );

    const handleScroll = (event) => {
      const container = event.currentTarget;
      // deltaY를 통해 가로 스크롤
      container.scrollLeft += event.deltaY;
      container.scrollLeft += event.deltaX;
    };

    const setDefaultImage = (event) => {
      event.target.src = require('@/assets/placeholder-image.png');
      event.target.classList.add('error-image');
    };

    const convertYoutubeLink = (link) => {
      if (!link || typeof link !== 'string') return '';
      return link.includes('watch?v=')
        ? link.replace('watch?v=', 'embed/')
        : link;
    };

    const isClickable = (item) => {
      return !!(item.thumbnails || item.videoLink);
    };

    const handleItemClick = (item, index) => {
      if (isClickable(item)) {
        router.push({
          name: 'ContentDetailView',
          params: {
            workId: 'Network',
            contentId: index
          }
        });
      }
    };

    const getItemStyles = (item) => {
      return {
        cursor: isClickable(item) ? 'pointer' : 'default',
        pointerEvents: isClickable(item) ? 'auto' : 'none'
      };
    };

    return {
      networkData,
      handleScroll,
      setDefaultImage,
      convertYoutubeLink,
      handleItemClick,
      getItemStyles
    };
  }
};
</script>

<style scoped>



.network-gallery-container {
  margin-bottom: 10px;
  padding-top: 5px;
  width: 100%;
}

.network-gallery-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr)); /* 자동 크기 조정 */
  gap: 5px; /* 이미지 간격 */
}

.network-img-wrap {
  position: relative;
  overflow: hidden;
}

.network-responsive-image {
  width: 100%;
  height: auto;
  aspect-ratio: 3 / 4;
  object-fit: cover;
  transition: filter 0.5s ease, opacity 0.5s ease;
}

.network-responsive-image:hover,
.network-video:hover {
  filter: invert(0.8);
}

.network-image-title {
  padding-top: 5px;
  font-size: 14px;
  color: black;
  text-align: center;
}

.network-video {
  width: 100%;
  aspect-ratio: 16 / 9;
  object-fit: cover !important;
  transition: filter 0.5s ease;
}

</style>